<template>
    <b-modal
        id="modal-sede"
        header-bg-variant="primary"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        :body-class="`${Theme === 'dark' && 'dark'} dialog2`"
        dialog-class="dialog"
        centered
    >
        <template #modal-header>
            <!-- Emulate built in modal header close button action -->
            <h5 class="text-white">Seleccione una sede</h5>
        </template>
        <b-card class="p-3">
            <b-list-group>
                <b-list-group-item
                    v-for="(sede, index) in sedes"
                    :key="index"
                    @click="setSelectedSede(sede)"
                    class="d-flex justify-content-between align-items-center"
                >
                    {{ sede.descripcion }}
                    <feather
                        v-b-popover.hover.top="'No seleccionada'"
                        v-if="selectedSede !== sede.id"
                        type="square"
                        role="button"
                        class="mr-2 text-secondary"
                    ></feather>
                    <feather
                        v-else
                        v-b-popover.hover.top="'Sede seleccionada'"
                        type="check-square"
                        role="button"
                        class="mr-2 text-blue-active"
                    ></feather>
                </b-list-group-item>
                <!-- <b-list-group-item href="#" active>Link with active state</b-list-group-item>
        <b-list-group-item href="#">Action links are easy</b-list-group-item>
        <b-list-group-item href="#foobar" disabled>Disabled link</b-list-group-item> -->
            </b-list-group>
        </b-card>
    </b-modal>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import services from '@/boot/axios'

const { API } = services

export default {
    name: 'ModalSede',
    data: () => ({
        selectedSede: null
    }),
    props: { sedes: { type: Array, default: () => [] } },
    methods: {
        setSelectedSede(sede) {
            // console.log(sede)
            this.selectedSede = sede.id
            this.setUltimaSedeAPI(sede)
            this.$emit('selectedSede', sede)
            // setTimeout(() => {
            //   this.setUltimaSede(sede)
            // }, 500)
            this.close()
        },
        setUltimaSedeAPI(sede) {
            API.POST({
                url: 'general/selec-ultima/sede/crud',
                data: {
                    p_datajson: {
                        sede_id: sede.id,
                        usuario_id: this.getUserData.id
                    },
                    p_opc: 'UPDATE',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.setUltimaSede(sede)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        close() {
            this.$bvModal.hide('modal-sede')
        },
        ...mapMutations('sedes', ['setUltimaSede'])
    },
    computed: {
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 5 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson']),
        ...mapState(['Theme'])
    }
}
</script>

<style>
.dialog {
    min-width: 80%;
}

.dialog2 {
    min-height: auto;
}
</style>
